import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/pic11.jpg'

const Address = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> Who we address </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    
                    <h3> Direct beneficiaries </h3>
                        <ul>
                            <li> coaches that are working in the domain of adapted/unified sport, in general, and
coaches that are working in the domain of adapted/unified dance, in particular </li> 
                            <li>  NGOs/public institutions/training centers in the domain of social inclusion and in the
domain of sport/dance, that are interested in the training of coaches for the career of
adapted/unified dance; </li>
                            <li> people with and without disabilities </li>
                        
                        </ul>

                        <h3> Indirect beneficiaries: </h3>
        <ul>
            <li>families or family members that have in care people with disabilities, legal
representatives, specialists and community members;</li>
            <li> representatives of institutes for Public Policy in the partner countries;</li>
            <li> representatives of the public and private institutions. </li>
        </ul>

                </div>
            </section>
        </div>

    </Layout>
)

export default Address